import {DniCache} from "../app";

export type CacheItem<T> = {
    data: T;
    expires: number;
}

class LocalStorage implements DniCache {
    static internalCache: Record<string, CacheItem<any> | null> = {};
    private readonly ttl: number;

    constructor(ttl: number) {
        this.ttl = ttl;
    }

    private hydrateInternalCache(key: string): void
    {
        if (LocalStorage.internalCache[key] !== undefined) {
            return;
        }

        const result = localStorage.getItem(key);

        if (result === null) {
            LocalStorage.internalCache[key] = null;

            return;
        }

        LocalStorage.internalCache[key] = JSON.parse(result);
    }

    get<T>(key: string): T | null {
        this.hydrateInternalCache(key);

        const result = LocalStorage.internalCache[key];

        if (result === null || result.data === null) {
            window.dispatchEvent(new CustomEvent('800dni.cache.miss', {
                detail: {
                    key,
                }
            }));

            return null;
        }

        if (result.expires && result.expires < new Date().getTime()) {
            window.dispatchEvent(new CustomEvent('800dni.cache.prune', {
                detail: {
                    key,
                }
            }));

            this.set(key, null);

            return null;
        }

        window.dispatchEvent(new CustomEvent('800dni.cache.hit', {
            detail: {
                key,
            }
        }));

        // hit
        return result.data;
    }

    set<T>(key: string, data: T, ttl = this.ttl): void {
        const expires = ttl === 0
            ? 0
            : (new Date()).getTime() + ttl * 1000;

        localStorage.setItem(
            key,
            JSON.stringify({
                data,
                expires,
            })
        )

        window.dispatchEvent(new CustomEvent("800dni.cache.set", {
            detail: {
                key,
                data,
            }
        }));
    }
}

export {LocalStorage}