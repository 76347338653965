import {CallTrackingResponse} from "../../types";
import {DniCache, CallTrackingRepository} from "../app";
import {error} from "../debugging";

const SESSION_KEY = '800cltrk.session';

type CallTrackingOptions = {
    storage: DniCache,
    url: string,
    landingPage: string,
    referrer: string | null,
    source: string | null,
}

export function initCallTracking(options: CallTrackingOptions): CallTrackingRepository {
    const init = async (sessionId?: string): Promise<CallTrackingResponse> => {
        const response : Response = await fetch(options.url, {
            method: 'POST',
            body: JSON.stringify({
                sessionId,
                landingPage: options.landingPage,
                referrer: options.referrer,
                source: options.source,
                date: new Date().toISOString(),
            }),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
        });

        if (response.status !== 200) {
            error(await response.text());

            throw new Error("Received non-200 response while fetching swaps");
        }

        const payload = await response.json() as {data: CallTrackingResponse};

        return payload.data;
    };

    const existingSession = options.storage.get<CallTrackingResponse>(SESSION_KEY);

    const results = init(existingSession?.sessionId);

    results.then((response) => {
        options.storage.set<CallTrackingResponse>(SESSION_KEY, response);
    });

    return async () => results;
}
