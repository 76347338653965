import {PageContext} from "./app";

export function resolveSource(page: PageContext): string {
    const ref = page.referrer || 'direct';
    const url = page.url.toString();
    const host = page.referrer
        ? new URL(page.referrer).host
        : '';

    const mediumPaidPattern = /utm_medium=([cp]pc|paid_social|paid|.*_ad.*)/i;

    if (url.match(/ndclid=/i)) {
        return 'nextdoor_paid';
    }

    if (host.match(/doubleclick/i) || url.match(/dclid=|wbraid=|gbraid=/i)) {
        return 'google_paid';
    }
    if (host.match(/google/i) && !host.match(/mail\.google\.com/i)) {
        if (url.match(/gclid=/i)) return 'google_paid';
        return url.match(/googleadservices|utm_(medium|source)=[cp]pc|matchtype|adposition/i) ? 'google_paid' : 'google_organic';
    }
    if (url.match(mediumPaidPattern)) {
        if (host.match(/yahoo/i) && !host.match(/mail\.yahoo\.com/i)) {
            return 'yahoo_paid';
        }
        if (host.match(/youtube\./i) || url.match(/utm_source=.*youtubref.*/i)) {
            return url.match(/gclid=/i) ? 'youtube_paid' : 'youtube_organic';
        }
        if (url.match(/msclkid=/i)) {
            return 'bing_paid';
        }
    }

    const platforms = [
        { name: 'bing', refPattern: /bing\.|msn\.com/i, landingPagePattern: /msclkid=|utm_source=.*bing/i, paid: 'bing_paid', organic: 'bing_organic' },
        { name: 'facebook', refPattern: /facebook\.|fbclid=/i, landingPagePattern: /utm_source=.*facebook/i, paid: 'facebook_paid', organic: 'facebook_organic' },
        { name: 'instagram', refPattern: /instagram\./i, landingPagePattern: /utm_source=.*instagram/i, paid: 'instagram_paid', organic: 'instagram_organic' },
        { name: 'duckduckgo', refPattern: /duckduckgo\./i, landingPagePattern: /utm_source=.*duckduckgo/i, paid: 'duckduckgo_paid', organic: 'duckduckgo_organic' },
        { name: 'nextdoor', refPattern: /nextdoor\./i, landingPagePattern: /utm_source=.*nextdoor/i, paid: 'nextdoor_paid', organic: 'nextdoor_organic' },
        { name: 'linkedin', refPattern: /linkedin\./i, landingPagePattern: /utm_source=.*linkedin/i, paid: 'linkedin_paid', organic: 'linkedin_organic' },
        { name: 'tiktok', refPattern: /tiktok\.|ttclid=/i, landingPagePattern: /utm_source=.*tiktok/i, paid: 'tiktok_paid', organic: 'tiktok_organic' },
        { name: 'twitter', refPattern: /twitter\./i, landingPagePattern: /utm_source=.*twitter/i, paid: 'twitter_paid', organic: 'twitter_organic' },
        { name: 'pinterest', refPattern: /pinterest\./i, landingPagePattern: /utm_source=.*pinterest/i, paid: 'pinterest_paid', organic: 'pinterest_organic' },
        { name: 'spotify', refPattern: /spotify\./i, landingPagePattern: /utm_source=.*spotify/i, paid: 'spotify_paid', organic: 'spotify_organic' },
        { name: 'yelp', refPattern: /yelp\./i, landingPagePattern: /utm_source=.*yelp|utm_(medium|source|campaign)=yelp_ad|campaign_code=yelp_ad/i, paid: 'yelp_paid', organic: 'yelp_organic' }
    ];

    for (const platform of platforms) {
        if (host.match(platform.refPattern)) {
            return url.match(platform.landingPagePattern) || url.match(mediumPaidPattern) ? platform.paid : platform.organic;
        }
    }

    if (host.length > 1) {
        return host;
    }

    return 'direct';
}