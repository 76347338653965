import { isMobile } from 'is-mobile';

import { getDataLayerSnapshot } from './asc-data-layer';
import { SwapEvent } from './events';
import { warn } from './debugging';

const trackedNodes = new WeakMap<Node, boolean>();
let eventFired = false;

const addClickListenerToNode = (node: Element, numberE164: string) => {
    if (trackedNodes.has(node)) {
        return;
    }

    trackedNodes.set(node, true);

    const fireClickToCallEvent = () => {
        if (eventFired) {
            return;
        }

        // We could have an event listener bound to multiple elements 
        // such that the event fires multiple times for a single click.
        // We do not want to mess with event propagation as we 
        // might break something outside of our control.
        // This code guards against excessive events firing.
        setTimeout(() => eventFired = false, 5000);
        eventFired = true;

        // Firefox sometimes aborts the request with NS_BINDING_ABORTED error. 
        // By using a timeout, this doesn't happen anymore.
        setTimeout(() => {
            window.dispatchEvent(new CustomEvent('800dni.asc-event', {
                detail: {
                    ascEventName: 'asc_click_to_call',
                    ascEventData: {
                        ...getDataLayerSnapshot(),
                        comm_phone_number: numberE164,
                    },
                },
            }));
        });
    }

    // Many devices have the option to call after selecting text.
    //   (e.g. with a long press on the phone number)
    // We should treat that as a click to call, as that's probably the intention.
    node.addEventListener('selectstart', fireClickToCallEvent);
    node.addEventListener('click', fireClickToCallEvent);
};

const addClickTracking = (node: Element, text: string, numberE164: string) => {
    // Probably replacing an attribute instead of node text
    if (  node.childNodes.length === 1 && 
          node.childNodes[0].nodeType === Node.TEXT_NODE) {
        // Confirm that the text is in the href attribute
        if (node.nodeType === Node.ELEMENT_NODE && String(node.getAttribute('href')).substring(4) === text) {
            addClickListenerToNode(node, numberE164);
            return;
        }

        if (! node.childNodes[0].nodeValue?.includes(text)) {
            warn('Cannot handle this type of node', node);
            return;
        }
    }

    // Node is a has a single child text node with nothing but the text
    if (node.childNodes.length === 1 && node.childNodes[0].nodeType === Node.TEXT_NODE) {
        const trimmedText = (node.childNodes[0].nodeValue || '').trim();
        if (trimmedText === text) {
            addClickListenerToNode(node, numberE164);
            return;
        }
    }

    // Node has multiple child nodes or the text is not the only thing in the node
    for (let i = 0; i < node.childNodes.length; i++) {
        const childNode = node.childNodes[i];

        if (childNode.nodeType === Node.ELEMENT_NODE) {
            addClickTracking(childNode as Element, text, numberE164);
            continue;
        }

        if (childNode.nodeValue === null ||
            childNode.parentNode === null ||
            childNode.nodeType !== Node.TEXT_NODE) {
            continue;
        }
        
        const parts = childNode.nodeValue.split(text);
        const parentNode = childNode.parentNode;

        for (let i = 0; i < parts.length; i++) {
            // Insert any text that is not part of the phone number
            parentNode.insertBefore(document.createTextNode(parts[i]), childNode);

            if (i < parts.length - 1) {
                // Insert the phone number, wrapped in a clickable span
                const wrapperSpan = document.createElement('span');
                // This should help prevent any CSS applying to this element.
                wrapperSpan.setAttribute('style', 'all: unset !important');
                wrapperSpan.append(document.createTextNode(text));
                parentNode.insertBefore(wrapperSpan, childNode);
                addClickListenerToNode(wrapperSpan, numberE164);
            }
        }

        parentNode.removeChild(childNode);
    }
};

export const initAscClickToCall = () => {
    // Only run on mobile/tablet devices
    if (!isMobile({ tablet: true })) {
        return;
    }

    window.addEventListener('800dni.swap', (e: CustomEvent<SwapEvent>) => {
        const { original, replacementE164, node } = e.detail;

        if (node.parentNode !== null && 
            node.parentNode.nodeType === Node.ELEMENT_NODE) {
            addClickTracking(node.parentNode as Element, original, replacementE164);
        }
    });
};
